export interface IState {
	puff: IPuff
}

export interface IPuff {
	url: string,
	rel: string,
	title: string,
	text: string,
	imageUrl: string,
	imageAlt: string
}

const deafultState: IState = {
	puff: {
		url: 'url',
		rel: 'rel',
		title: 'title',
		text: 'text',
		imageUrl: 'imageUrl',
		imageAlt: 'imageAlt'
	}
}

export default deafultState;

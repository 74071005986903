





















import Vue from "vue";

interface PuffProps {
  id: string;
  title: String;
  text: String;
  url: String;
  rel: String;
  imageUrl: string;
  imageAlt: string;
}

export default Vue.extend<{}, {}, {}, PuffProps>({
  name: "Puff",
  computed: {
    title() {
      return this.$store.getters.title;
    },
    text() {
      return this.$store.getters.text;
    },
    url() {
      return this.$store.getters.url;
    },
    rel() {
      return this.$store.getters.rel;
    },
    imageUrl() {
      return this.$store.getters.imageUrl;
    },
    imageAlt() {
      return this.$store.getters.imageAlt;
    },
    id() {
      var title = this.$store.getters.title;
      return "puff-" + title.trim().replace(/\s/g, "_");
    },
  },
});

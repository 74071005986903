import { Getter } from "vuex";
import { IState } from './state';

const title: Getter<IState, IState> = (state) => state.puff.title;
const text: Getter<IState, IState> = (state) => state.puff.text;
const imageUrl: Getter<IState, IState> = (state) => state.puff.imageUrl;
const imageAlt: Getter<IState, IState> = (state) => state.puff.imageAlt;
const url: Getter<IState, IState> = (state) => state.puff.url;
const rel: Getter<IState, IState> = (state) => state.puff.rel;

export default {
	title,
	text,
	imageUrl,
	imageAlt,
	url,
	rel
}
